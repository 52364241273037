import styled from "styled-components";

export const OutletContainer = styled.div`
    padding:20px;
    text-align:center;
`
export const Img =styled.img`
    height:40px;
    position:fixed;
    right:10px;
    bottom:10px;
    cursor:pointer;
    @media only screen and (max-width: 600px) {
        height:30px;
    }
` 