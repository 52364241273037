import styled from "styled-components";
export const Container = styled.div`
    &>h2{
        margin-bottom:8px;
        font-size: 1.3rem;
    }
`
export const List = styled.div`
    cursor:pointer;
    padding:5px;
    &:hover{
        background:lightgray;
    }
`