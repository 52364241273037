import React from 'react'

function Notfound() {
  return (
    <center>
        <h2>Page not found!</h2>
        <br />
        <img style={{ width: '100px' }} src='/icons/404.png'></img>
    </center>
  )
}

export default Notfound