import styled from "styled-components";

export const Container = styled.div`
    &>h4{
        margin: 5px;
        cursor: pointer;
        padding: 2px 8px;
        font-weight: normal;
        border-radius: 10000px;
    }
`